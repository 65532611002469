import { graphql } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import PageLayout from "../components/PageLayout";
import AcceptableUsePolicy from "../../static/pdfs/Acceptable_Use_Policy.pdf";
import {
  TITLE_FONTSIZE,
  SUBTITLE_FONTSIZE,
  TEXT_FONTSIZE,
  TERMS,
} from "../utilities/constants";
import SwitchButton from "../components/SwitchButton";
import { constants } from "../redux/constants";
import { useDispatch } from "react-redux";
import { saveRouteDetails } from "../redux/actions/routeActions";

const AREA_WIDTH = "45%";
const MyContainer = styled.div`
  padding: 10%;
  background-color: white;
`;

const TitleArea = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 45rem;
  justify-content: space-between;
  @media (max-width: 805px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: ${TITLE_FONTSIZE};
  font-weight: 600;

  @media (max-width: 450px) {
    font-size: 30px;
  }
  @media (max-width: 380px) {
    font-size: 25px;
  }
`;

const TermSelectArea = styled.div`
  margin-bottom: 30px;
`;

const SelectBox = styled.div`
  background-color: #ecfaff;
  border-radius: 10px;
  width: ${AREA_WIDTH};
  padding: 1rem;
  color: #0494ca;
  font-weight: 500;
  cursor: pointer;
  font-size: ${SUBTITLE_FONTSIZE};
  & ~ & {
    margin-top: 20px;
  }
`;

const TermDetail = styled.div`
  margin-top: 30px;
  font-size: ${TEXT_FONTSIZE};
  font-weight: 400;
  p {
    font-size: ${TEXT_FONTSIZE};
    font-weight: 400;
  }
`;
const DetailTitle = styled.div`
  color: #0494ca;
  font-weight: 500;
  font-size: ${SUBTITLE_FONTSIZE};
  margin-bottom: 20px;
`;
const DetailDescription = styled.div``;
const DetailSubtitle = styled.div`
  font-size: ${SUBTITLE_FONTSIZE};
  font-weight: 600;
  margin-bottom: 5px;
`;

const DetailPdf = styled.a`
  color: #0494ca;
  text-decoration: underline !important;
`

const DetailContent = styled.div`
  margin-top: 2%;
  whitespace: "pre-wrap";
`;

const DetailParagraph = styled.div`
  min-height: 5px;
`;

const DetailParagraphBold = styled.div`
  min-height: 5px;
  font-weight: 600;
`;

const formatString = (text: string) => {
  const stringList = text.split("\n");
  return (
    <>
      {stringList.map((s, i) => {
        if (s.includes("https")) {
          let length = s.length
          let linkIndex = s.indexOf("https")
          let periodIndex = length - 1;
          let firstPart = s.substring(0, linkIndex);
          let link = s.substring(linkIndex, periodIndex);
          let lastPart = s.substring(periodIndex, length);
          return (
            <DetailParagraph key={i}>
              <Trans>{firstPart}</Trans>
              <a href={link}>{link}</a>
              <Trans>{lastPart}</Trans>
            </DetailParagraph>
          )
        } else if (s.trim() === '<b>Cancellation and Refund Policy</b>') {
          let boldedContent = s.substring(3, s.length - 4);
          return (
            <DetailParagraphBold key={i} id='cancellationAndRefundPolicy'>
              <Trans>{boldedContent}</Trans>
            </DetailParagraphBold>
          )
        } else if (s.startsWith('<b>') && s.endsWith('</b>')) {
          let boldedContent = s.substring(3, s.length - 4);
          return (
            <DetailParagraphBold key={i}>
              <Trans>{boldedContent}</Trans>
            </DetailParagraphBold>
          )
        }
        return (
          <DetailParagraph key={i}>
            <Trans>{s}</Trans>
          </DetailParagraph>
        )
      })}
    </>
  );
};

const DEFAULT_COUNTRY = "Canada";
const SWITCHED_COUNTRY = "US";
const DEFAULT_TERM_ID = 0;

const TermOfService = () => {
  const [country, setCountry] = useState(DEFAULT_COUNTRY);
  const [termId, setTermId] = useState(DEFAULT_TERM_ID);

  const privacyPolicyRef = useRef(null);

  const dispatch = useDispatch();

  const fromSectionLinkHandle = (country, section) => {
    if (country && country.toLowerCase() === constants.USA.toLowerCase()) {
      console.log(country);
      setCountry(SWITCHED_COUNTRY);
    }

    let content;

    if (section) {
      setTimeout(() => {
        // cancellation and refund policy link
        if (section.toLowerCase() === 'early-cancellation') {
          content = document.getElementById('cancellationAndRefundPolicy');
        
        // privacy policy link
        } else if (section.toLowerCase() === 'privacy-policy') {
          content = document.getElementById('privacyPolicy');
        }
        let y = content.getBoundingClientRect().top + window.pageYOffset - 165;
        window.scrollTo({top: y, behavior: 'smooth'});
      }, 100)
    }

    window.history.pushState("object or string", "Title", "/terms-of-service");
  }

  useEffect(() => {
    dispatch(saveRouteDetails("terms-of-service") as any);

    const section = new URLSearchParams(window.location.search).get(
      "section"
    );
  
    const countryParam = new URLSearchParams(window.location.search).get(
      "country"
    );

    if (countryParam || section) {
      fromSectionLinkHandle(countryParam, section);
    }
  }, []);

  const onSwitchCountry = () =>
    country === DEFAULT_COUNTRY
      ? setCountry(SWITCHED_COUNTRY)
      : setCountry(DEFAULT_COUNTRY);

  return (
    <PageLayout backgroundColor="#f2fafc">
      <title>Terms of Service | PhoneBox</title>
      <MyContainer>
        <TitleArea>
          <Title>
            <Trans>{"Terms of Service - " + country}</Trans>
          </Title>
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <SwitchButton
              width={120}
              height={60}
              imgOn="/images/shop/CanadaFlag.png"
              imgOff="/images/shop/us.png"
              textOn="USA"
              textOff="CAN"
              onSwitch={onSwitchCountry}
              defaultState={country === "Canada" ? true : false}
            />
          </div>
        </TitleArea>
        <TermDetail>
          <DetailDescription>
            {TERMS.find((x) => x.country == country).description.map((d, i) => (
              <div key={i}>
                {formatString(d)}
              </div>
            ))}
          </DetailDescription>
          {TERMS.find((x) => x.country == country)?.content.map((t, i) => { 
            if (t.title === "Privacy Policy") {
              console.log(t);
            }
            return (
              <DetailContent key={i}>
                {t.title === "Privacy Policy" ?
                  <DetailSubtitle id="privacyPolicy" ref={privacyPolicyRef}>
                    <Trans>{t.title}</Trans>
                  </DetailSubtitle>
                  :
                  <DetailSubtitle>
                    <Trans>{t.title}</Trans>
                  </DetailSubtitle>
                }
                <DetailDescription>
                  <div>
                    <Trans>{formatString(t.text)}</Trans>
                  </div>
                </DetailDescription>

                {t.title === "Changes to This Privacy Statement" && 
                  <DetailPdf href={AcceptableUsePolicy} download>
                    <Trans>Acceptable Use Policy</Trans>
                  </DetailPdf>}
              </DetailContent>
          )})}
        </TermDetail>
      </MyContainer>
    </PageLayout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default TermOfService;
